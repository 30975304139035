import React, {useEffect, useState} from 'react';
import SimpleMap from "./MyMapComponent";
// import SimpleMap from "./MyMapComponent2";

const MyMarker = ({text}) => <div>{text}</div>;

function App() {
    const [markers, setMarkers] = useState([]);
    const [isLoaded, setIsLoaded] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        const loadAdList = async () => {
            await fetch('api/item/6331da0cff8054c05c76e31c')
                .then(res => res.json())
                .then(
                    (items) => {
                        setIsLoaded(true);
                        setMarkers(React.Children.toArray(items.map(item =>
                            <MyMarker
                                lat={item.location.lat}
                                lng={item.location.lng}
                                text={item.name}
                            />)
                        ));
                    },
                    (error) => {
                        setIsLoaded(true);
                        setError(error)
                    }
                )
        }
        loadAdList();
    }, []);

    return isLoaded ? (
        <div>
            <SimpleMap>
                {markers}
            </SimpleMap>
        </div>
    ) : null;
}

export default App;
