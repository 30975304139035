import React from "react";
import GoogleMapReact from 'google-map-react';

export default function SimpleMap({children}) {
    const defaultProps = {
        center: {
            lat: 49.8641424,
            lng: 15.7291695
        },
        zoom: 8.34
    };

    return (
        // Important! Always set the container height explicitly
        <div style={{height: '100vh', width: '100%'}}>
            <GoogleMapReact
                bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY}}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
            >
                {children}
            </GoogleMapReact>
        </div>
    );
}